.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
    z-index: 999999322322; /* Place it behind the loader */
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999322323 !important;
    width: 300px !important;
    height: 300px;
    background-image: url('../pages/image/Infinity-1.5s-200px.gif') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.ql-snow{
    border: none !important;
    
}